/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        $(document).ready(function(){
    $('.customer-logos').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
    });
});

        $('.logo-wrap').matchHeight({ property: 'min-height' });
        $('.basicPresenterContent').matchHeight({ property: 'min-height' });

        $(function() {
    $('#topNav .matchHeight').matchHeight();
});

   

        $(function() {
    $('.equalHeights').matchHeight();
     $('.calloutContainer .basicPresenterContent').matchHeight({
    byRow: true,
    property: 'height',
    target: null,
    remove: false
});
});


$(document).ready(function () {
      $("#logoParade").smoothDivScroll({
        autoScrollingMode: "onStart",
        autoScrollingDirection: "endlessLoopRight",
        autoScrollingStep: 1,
        manualContinuousScrolling: true,
        touchScrolling: true,
        hotSpotScrolling: false, 
        autoScrollingInterval: 25 
      });

      // Logo parade
      $("#logoParade").bind("mouseover", function () {
        $(this).smoothDivScroll("stopAutoScrolling");
      }).bind("mouseout", function () {
        $(this).smoothDivScroll("startAutoScrolling");
      });

    });


$(function() {
    $('.venue').matchHeight();
    $('.agenda-row>div').matchHeight({ property: 'min-height' });
    $('.calloutContainer').matchHeight({ property: 'min-height' });
    $('.logo-height').matchHeight({ property: 'min-height' });
    
});

// $('#topNav').sticky({topSpacing:0});
$('#event-crumbs').sticky({
  topSpacing:100, 
  getWidthFrom: 'body'
});

$('a[href^="#"]').on('click',function (e) {
      e.preventDefault();

      var target = this.hash;
      var $target = $(target);

      $('html, body').stop().animate({
          'scrollTop': $target.offset().top
      }, 900, 'swing', function () {
          window.location.hash = target;
      });
  });


$( ".agenda-time:even" ).addClass('agenda-even');

function equalHeights(equalHeights) {
    var findClass = document.getElementsByClassName(equalHeights);
    var tallest = 0; 
  // Loop over matching divs
  for(i = 0; i < findClass.length; i++)
  {
    var ele = findClass[i];
    var eleHeight = ele.offsetHeight;
    tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
  }
  for(i = 0; i < findClass.length; i++)
  {
    findClass[i].style.height = tallest + "px";
  }
}





// typekit stuff

  (function(d) {
    var config = {
      kitId: 'ual4ojv',
      scriptTimeout: 3000,
      async: true
    },
    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
  })(document);


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
